import { Site } from "@libry-content/types";
import { platformUrl } from "./platform";

const addDefaulLanguage = (site: Pick<Site, "defaultLanguage">, skipLanguage: boolean, url: string): string =>
  skipLanguage || !site.defaultLanguage ? url : `${url}/${site.defaultLanguage}`;

export const platformRootUrl = new URL(`${platformUrl.protocol}//${platformUrl.host}`);

export const splashSiteUrl = platformRootUrl;

/**
 * Get the base URL for a given site.
 *
 * @param site - Site instance.
 * @returns URL
 */
export const getSiteUrl = (
  site: Pick<Site, "domain" | "defaultLanguage" | "_type">,
  options = { skipLanguage: false }
): string => {
  const { domain } = site;
  const [platformDomain, platformPort] = platformUrl.host.split(":"); // Split on ":" for localhost
  const isCustomDomain = !domain?.endsWith(platformDomain!); // If the domain does not end with the platform domain (e.g. froland.bibliotek.io), it's a custom domain (e.g. tnb.no)
  const isLocalhost = platformDomain === "localhost";

  // Custom domains are not supported on localhost. Using a readable url as error message as it should be easy to spot in logs
  if (isLocalhost && isCustomDomain) return `/custom-domain/${domain}/not-supported-on-localhost`;

  const protocol = isLocalhost ? "http:" : "https:"; // We use http for localhost, but only support https for production
  const port = platformPort ? `:${platformPort}` : ""; // Port is only used for localhost
  const url = `${protocol}//${domain}${port}`;
  return addDefaulLanguage(site, options.skipLanguage, url);
};

export const adminSiteUrl = new URL(`${platformUrl.protocol}//admin.${platformUrl.host}`);

export const isAdminSiteDomain = (domain?: string): boolean => domain === adminSiteUrl.host.split(":")[0]; // Split on ":" for localhost
