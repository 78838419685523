import { UTCDateToNorwegian } from "@libry-content/common";
import { dateFnsLocaleMap, FrontendLocale } from "@libry-content/localization";
import dateFnsFormat from "date-fns/format";
import isSameMonth from "date-fns/isSameMonth";
import isSameYear from "date-fns/isSameYear";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";

export function localizedFormat(date: string | undefined, format: string, lang: FrontendLocale) {
  if (typeof date !== "string") return "Ukjent dato";

  try {
    return dateFnsFormat(UTCDateToNorwegian(new Date(date)), format, { locale: dateFnsLocaleMap[lang] });
  } catch (err) {
    console.error("Could not format date:" + date, err);
    return date;
  }
}

export const isValidDate = (dateString: string, dateFormat: string) => {
  const parsed = parse(dateString, dateFormat, new Date());
  return parsed instanceof Date && !isNaN(parsed.getTime());
};

const nb = dateFnsLocaleMap.nb;

/**
 *
 * @param startDateString
 * @param endDateString
 * @returns string | undefined
 */
export const formatDateRange = (startDateString: string, endDateString: string): string | undefined => {
  if (!startDateString || !endDateString) return;

  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  if (!isValid(startDate) || !isValid(endDate)) return;

  const startDay = dateFnsFormat(startDate, "d.", { locale: nb });
  const endDay = dateFnsFormat(endDate, "d.", { locale: nb });

  const startMonth = dateFnsFormat(startDate, "MMMM", { locale: nb });
  const endMonth = dateFnsFormat(endDate, "MMMM", { locale: nb });

  const startYear = dateFnsFormat(startDate, "yyyy");
  const endYear = dateFnsFormat(endDate, "yyyy");

  if (!isSameYear(startDate, endDate)) {
    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  }

  const isCurrentYear = isSameYear(startDate, new Date());
  const yearPostFix = isCurrentYear ? "" : ` ${startYear}`;

  if (isSameMonth(startDate, endDate) && isSameYear(startDate, endDate)) {
    return `${startDay} - ${endDay} ${startMonth}${yearPostFix}`;
  }

  return `${startDay} ${startMonth} - ${endDay} ${endMonth}${yearPostFix}`;
};
