import { CombinedRichTextBlock } from "@libry-content/types";

export const richTextHasContent = (blocks?: CombinedRichTextBlock[]): boolean => {
  return !!blocks?.some((block: CombinedRichTextBlock) => {
    switch (block._type) {
      case "block":
        return block.children?.find(({ text }: any) => typeof text === "string" && !!text.trim());
      case "table":
        return true;
      case "gallery":
        return true;
      case "videoEmbed":
        return true;
      case "linkButton":
        return true;
    }
  });
};

const isBlockType = (block: CombinedRichTextBlock): block is Extract<CombinedRichTextBlock, { _type: "block" }> =>
  block._type === "block";

export const richTextToString = (blocks: CombinedRichTextBlock[] = [], blockSeparator = "\n\n"): string =>
  blocks
    .filter(isBlockType)
    .map(({ _type, children = [] }) => (_type === "block" ? children.map(({ text }: any) => text ?? "").join("") : ""))
    .join(blockSeparator);
