import { Modify } from "@biblioteksentralen/types";
import { Event, EventType, Library } from "@libry-content/types";
import groq from "groq";
import { ResolvedRepeatedEventOccurence } from "./repeatedEvents";
import { ResolvedServiceSummary, resolveServiceSummary } from "../../services/groq/resolveService";

export const resolveEventGroqProjection = groq`
  ...,
  'eventType': eventType->{_id, label},
  'library': library->{name, _id, slug, _type},
  relatedService {
    ...,
    "summary": reference->{${resolveServiceSummary}}
  }
`;

export type ResolvedEvent = Modify<
  Event | ResolvedRepeatedEventOccurence,
  {
    eventType?: Pick<EventType, "_id" | "label">;
    library?: Pick<Library, "name" | "_id" | "slug" | "_type">;
    relatedService?: NonNullable<Event["relatedService"]> & { summary?: ResolvedServiceSummary };
  }
>;

export const resolveEventSummaryGroqProjection = groq`
  {${resolveEventGroqProjection}}
  {
    _id,
    _type,
    eventSchedule,
    title,
    eventStatus, 
    teaser,
    promotion,
    image,
    externalLocation,
    location,
    library,
    eventType,
    targetAudiences,
    registration,
    series { id, title },
    relatedService
  }
`;

export type ResolvedEventSummary = Pick<
  ResolvedEvent,
  | "_id"
  | "_type"
  | "eventSchedule"
  | "title"
  | "eventStatus"
  | "teaser"
  | "promotion"
  | "image"
  | "externalLocation"
  | "location"
  | "library"
  | "eventType"
  | "targetAudiences"
  | "registration"
  | "relatedService"
> & { series?: Pick<ResolvedRepeatedEventOccurence["series"], "id" | "title"> }; // series is only defined if _type === "resolvedRepeatedEventOccurence", but couldnt find a way to type this
