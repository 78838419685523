import { FrontendLocale } from "@libry-content/localization";
import isSameDay from "date-fns/isSameDay";
import { localizedFormat } from "../../../../apps/frontend/src/utils/date";
import { DateHelper } from "../date/DateHelper";
import { ResolvedEvent, ResolvedEventSummary } from "./groq/resolveEvent";

export const timeString = (time: string | undefined, lang: FrontendLocale): string =>
  localizedFormat(time, "HH:mm", lang);

export const dateString = (date: string | undefined, lang: FrontendLocale): string =>
  localizedFormat(date, "EEEE, do MMMM", lang);

export const eventTimeString = (lang: FrontendLocale, event?: Pick<ResolvedEventSummary, "eventSchedule">): string =>
  `${timeString(event?.eventSchedule?.startsAt, lang)} - ${timeString(event?.eventSchedule?.endsAt, lang)}`;

export const isThisYear = (dateString: string | undefined): boolean =>
  dateString ? new Date(dateString).getFullYear() === new Date().getFullYear() : true;

export const getDateStringFormat = (showYear: boolean, allDay?: boolean): string => {
  if (allDay) return showYear ? "PP" : "EEEE, do MMMM";
  return showYear ? "EEEE, PPP" : "EEEE, do MMMM";
};

export const eventDateString = (
  languageCode: FrontendLocale,
  event: Pick<ResolvedEventSummary, "eventSchedule">
): string => {
  const { startsAt, endsAt, allDay } = event.eventSchedule || {};

  if (!startsAt || !endsAt) return "";

  const showYear = !isThisYear(startsAt) || !isThisYear(endsAt);
  const singleDayFormat = getDateStringFormat(showYear);
  const allDayFormat = getDateStringFormat(showYear, true);

  if (allDay && !isSameDay(new Date(startsAt), new Date(endsAt))) {
    const startDateString = localizedFormat(startsAt, allDayFormat, languageCode);
    const endDateString = localizedFormat(endsAt, allDayFormat, languageCode);
    return `${startDateString} til ${endDateString}`;
  }

  return localizedFormat(startsAt, singleDayFormat, languageCode);
};

export const eventIsFinished = ({ eventSchedule }: Pick<ResolvedEvent, "eventSchedule">): boolean => {
  const endsAt = eventSchedule?.endsAt;
  return !!endsAt && new DateHelper(endsAt).isBeforeNow();
};

export const eventIsCancelled = (eventStatus: ResolvedEvent["eventStatus"]) => eventStatus === "cancelled";

export const eventIsFull = (eventStatus: ResolvedEvent["eventStatus"]): boolean => eventStatus === "full";

export const eventIsOpen = (eventStatus: ResolvedEvent["eventStatus"]): boolean =>
  !eventIsCancelled(eventStatus) && !eventIsFull(eventStatus);

export const looksLikeEmail = (registrationUrlOrEmail: string | undefined) => !!registrationUrlOrEmail?.includes("@");
