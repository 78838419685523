import { Modify } from "@biblioteksentralen/types";
import { Event, RepeatedEventOccurrence } from "@libry-content/types";
import groq from "groq";
import { isEqual } from "radash";
import { ResolvedEvent, ResolvedEventSummary } from "./resolveEvent";

const resolveRepeatedEventOccurrenceSummary = groq`
  "_id": _key,
  eventSchedule,
  scheduleIsCustomized,
  "eventStatus": coalesce(eventStatus, ^.eventStatus),
  "title": coalesce(title, ^.title),
  "_type": "repeatedDateSummary"
`;

export type ResolvedRepeatedEventOccurrenceSummary = Pick<
  RepeatedEventOccurrence,
  "eventSchedule" | "eventStatus" | "title" | "scheduleIsCustomized"
> & {
  _id: string;
  _type: "repeatedDateSummary";
};

const resolveRepeatedDate = groq`
  ...^, // Spread the parent event
  "promotion": null, // Erase parent promotion
  "repeatedEventOccurrences": null, // Erase repeated event occurrences array to avoid confusion
  "repeated": null, // Erase repeated flag to avoid confusion
  
  ..., // Spread all repeated event occurrence fields, overwriting the parent event properties when defined
  ${resolveRepeatedEventOccurrenceSummary}, // Spread fields from repeated event occurrence-summary to reuse custom logic
  
  "series": {
    "id": ^._id, // Series id from the parent event
    "title": ^.title, // Series title from the parent event
    "repeatedEventOccurrences": ^.repeatedEventOccurrences[] {${resolveRepeatedEventOccurrenceSummary}}
  },
  "_type": "resolvedRepeatedEventOccurence" // Use unique type to distinguish from event-document
`;

export type ResolvedRepeatedEventOccurence = Modify<
  Omit<Event, "repeatedEventOccurrences" | "repeated"> & RepeatedEventOccurrence,
  {
    series: {
      id: string;
      repeatedEventOccurrences: ResolvedRepeatedEventOccurrenceSummary[];
      title: Event["title"]; // if the occurence does not have a custom title "series.title" will be the same as "title"
    };
    _type: "resolvedRepeatedEventOccurence";
  }
>;

// By performing the flattening in the query, we can use groq's ordering and pagination.
export const resolveRepeatedEvents = (repeatedDateFilter = "") => groq`
  {"occurrences": select(
    repeated => repeatedEventOccurrences[${repeatedDateFilter}] {${resolveRepeatedDate}},
    [@] // Non-repeated event
  )}
  .occurrences[] // Flatten
 `;

export const isRepeatedEventOccurence = <T extends ResolvedRepeatedEventOccurence>(doc: unknown): doc is T =>
  (doc as ResolvedRepeatedEventOccurence)?._type === "resolvedRepeatedEventOccurence";

export const isEvent = <T extends Event>(doc: unknown): doc is T => (doc as Event)?._type === "event";

export const getEventHeadings = (event: ResolvedEvent | ResolvedEventSummary) => {
  const heading = event.title;

  const serviceTitle = (event.relatedService?.useServiceTitle && event.relatedService?.summary?.title) || undefined;
  const eventSeriesTitle = (isRepeatedEventOccurence(event) && event.series.title) || undefined;
  const subheading = serviceTitle || eventSeriesTitle; // Service title takes precedence over series title

  return {
    heading,
    subheading: isEqual(heading, subheading) // If the heading and subheading are the same, we don't want to show the subheading
      ? undefined
      : subheading,
  };
};
