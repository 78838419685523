import createImageUrlBuilder from "@sanity/image-url";
import { Image } from "@sanity/types";
import { DecorativeImage, GalleryImage, ImageWithMetadata } from "@libry-content/types";
import { sanityConfig } from "./sanityConfig";

export const imageUrlBuilder = (source?: Image | DecorativeImage | ImageWithMetadata | GalleryImage) => {
  // Sånn som vi har satt opp bilder i sanity nå kan bilde-objektet eksistere selv om det ikke har asset. Det får createImageUrlBuilder til å krasje
  if (!source?.asset) return undefined;
  try {
    return createImageUrlBuilder(sanityConfig).image(source);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};
