import { EventType } from "@libry-content/types";
import groq from "groq";
import { z } from "zod";
import {
  ResolvedLibrarySummary,
  resolveLibrarySummary,
} from "../../../../../apps/frontend/src/components/library/sanityQuery";
import { TargetAudienceCode } from "../../targetAudiences";
import { resolveRepeatedEvents } from "./repeatedEvents";
import { ResolvedEventSummary, resolveEventSummaryGroqProjection } from "./resolveEvent";

const notRepeated = groq`(!repeated || !defined(repeated))`;

const repeatedDateOccurrenceIsOnOrAfterDateTime = (dateVariable: string) => groq`(
  @.eventSchedule.endsAt >= ${dateVariable}
)`;

const repeatedDateOccurrenceIsBeforeDateTime = (dateVariable: string) => groq`(
  @.eventSchedule.endsAt < ${dateVariable}
)`;

export const eventIsOnOrAfterDateTime = (dateVariable: string) => groq`(
  (${notRepeated} && eventSchedule.endsAt >= ${dateVariable}) ||
  (repeated && count(repeatedEventOccurrences[${repeatedDateOccurrenceIsOnOrAfterDateTime(dateVariable)}]) > 0)
)`;

export const eventIsBeforeDateTime = (dateVariable: string) => groq`(
  (${notRepeated} && eventSchedule.endsAt < ${dateVariable}) ||
  (repeated && count(repeatedEventOccurrences[${repeatedDateOccurrenceIsBeforeDateTime(dateVariable)}]) > 0)
)`;

export const eventFilters = Object.freeze({
  todayOrLater: eventIsOnOrAfterDateTime("$startOfToday"),
  beforeToday: eventIsBeforeDateTime("$startOfToday"),
  notFinished: eventIsOnOrAfterDateTime("now()"),
  finished: eventIsBeforeDateTime("now()"),
  lacksDates: groq`(
    (${notRepeated} && !defined(eventSchedule.startsAt)) ||
    (repeated && !defined(repeatedEventOccurrences[0].eventSchedule.startsAt))
  )`,
  repeatedEventOccurrenceNotFinished: repeatedDateOccurrenceIsOnOrAfterDateTime("now()"),
  repeatedEventOccurenceFinished: repeatedDateOccurrenceIsBeforeDateTime("now()"),
});

export const unFinishedEventsGroqFilter = groq`
  *[_type == 'event' && ${eventFilters.notFinished}]
    ${resolveRepeatedEvents(eventFilters.repeatedEventOccurrenceNotFinished)}
    | order(eventSchedule.startsAt asc)
`;

export const finishedEventsGroqFilter = groq`
  *[_type == 'event' && ${eventFilters.finished}]
    ${resolveRepeatedEvents(eventFilters.repeatedEventOccurenceFinished)}
    | order(eventSchedule.startsAt desc)
`;

export const eventsUrlStateSchema = z
  .object({
    kategori: z.array(z.string()).optional(),
    bibliotek: z.array(z.string()).optional(),
    malgruppe: z.array(z.string()).optional(),
    pagination: z.number().optional(),
  })
  .optional();

export type EventsUrlState = z.infer<typeof eventsUrlStateSchema>;

const eventsUrlFilters = groq` [
      (!defined($kategori) || length($kategori) == 0 || eventType->label[$lang] in $kategori) &&
      (!defined($bibliotek) || length($bibliotek) == 0 || library->slug in $bibliotek) &&
      (!defined($malgruppe) || length($malgruppe) == 0 || array::intersects(targetAudiences, $malgruppe))
  ]`;

export type FilteredEventsWithRelevantFacets = {
  events: ResolvedEventSummary[];
  facets: RelevantEventFacets;
};

export type RelevantEventFacets = {
  libraries: ResolvedLibrarySummary[];
  eventTypes: EventType[];
  targetAudiences: TargetAudienceCode[];
};

export type FinishedOrUnfinishedEvents = "finished" | "unfinished";
export const getFilteredEventsWithRelevantFacets = (finishedOrUnfinishedEvents: FinishedOrUnfinishedEvents) => groq`{ 
    "filteredEvents": ${
      finishedOrUnfinishedEvents === "unfinished" ? unFinishedEventsGroqFilter : finishedEventsGroqFilter
    } ${eventsUrlFilters} ${resolveEventSummaryGroqProjection},
  }
  {
    "events": @.filteredEvents [$start..$end],
    "facets": {
      "libraries": UNSCOPED[_type == "library" && _id in array::unique(^.filteredEvents[].library._id)]{${resolveLibrarySummary}},
      "eventTypes": UNSCOPED[_type == "eventType" && _id in array::unique(^.filteredEvents[].eventType._id)],
      "targetAudiences": array::unique(@.filteredEvents[].targetAudiences[]),
    }
  }
`;
