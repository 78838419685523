import groq from "groq";
import { eventFilters } from "./eventFilters";
import { resolveRepeatedEvents } from "./repeatedEvents";
import { resolveEventGroqProjection } from "./resolveEvent";

// #eventIdOrKey since we need to handle repeated events. A repeated event occurence is identified by its _key in the repeatedEventOccurrences array
export const singleEventQuery = groq`*[ 
  _type == "event" && 
  (
    _id == $eventIdOrKey || 
    $eventIdOrKey in repeatedEventOccurrences[]._key
  )
]
${resolveRepeatedEvents(groq`
    $eventIdOrKey == ^._id && (${eventFilters.repeatedEventOccurrenceNotFinished}) || // If we query by the id of an event with repeated dates (and not a specific repeated date by querying the _key), we want to return the first upcomming repeated date. This is forexample used in preview
    _key == $eventIdOrKey
`)}
| order(eventSchedule.startsAt asc)
[0]
{ ${resolveEventGroqProjection} }
`;

//get single event without resolving repeated events, so you get the original repeated event
export const singleOriginalEventQuery = groq`*[ 
  _type == "event" && 
  _id == $id 
]
[0]
{ ${resolveEventGroqProjection} }
`;
