import { CombinedRichTextBlock } from "@libry-content/types";
import { EditorLocale, editorLocales, isValidFrontendLocale } from "./utils";

export type FieldValue = boolean | string | CombinedRichTextBlock[] | undefined;

export type LocalizedField<T = FieldValue> = Partial<Record<EditorLocale, T>>;

export type TranslateSanityContent = <T = FieldValue>(localizedObject?: LocalizedField<T>) => T | undefined;

export const translateSanity = <T = FieldValue>(
  localizedObject: LocalizedField<T> | undefined,
  locale: EditorLocale
): T | undefined => {
  if (typeof localizedObject === "string") {
    console.error("Attempted to get localized text from a string:", localizedObject);
    return localizedObject;
  }

  return localizedObject?.[getLocaleForSanityContent(localizedObject, locale)];
};

export const getLocaleForSanityContent = <T = FieldValue>(
  localizedObject: LocalizedField<T> | undefined,
  prefferedLang: EditorLocale
): EditorLocale => {
  if (!localizedObject) return prefferedLang;
  if (prefferedLang in localizedObject) return prefferedLang;

  // Random fallback
  return editorLocales.find((lang) => lang in localizedObject) ?? prefferedLang;
};

export const isLocalizedField = (value: unknown): value is object =>
  typeof value === "object" &&
  value != null &&
  Object.keys(value).every((key) => isValidFrontendLocale(key) || key === "_type");
